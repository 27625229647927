<template>
  <div class="pes">
    <div class="pes-title">PES报告</div>
    <div class="pes-select">
      <div class="pes-select-1 select-item">
        <el-select v-model="value" placeholder="请选择" @change="bindPsType">
          <el-option
            v-for="item in options1"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="pes-select-2 select-item" v-if="isHide">
        <el-select
          v-model="valueType"
          placeholder="选择类型"
          @change="bindClass"
        >
          <el-option
            v-for="item in options2"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="pes-select-3 select-item" v-if="isHide">
        <el-select
          v-model="valueClass"
          placeholder="选择班级"
          @change="bindStu"
        >
          <el-option
            v-for="item in options3"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="pes-select-4 select-item" v-if="isStu">
        <el-select v-model="valueStu" placeholder="选择幼儿" @change="bindBgao">
          <el-option
            v-for="item in options4"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>

    <div class="pes-content">
      <div class="pes-content-title">测试报告</div>
      <div class="pes-content-list" v-if="reportList.length > 0">
        <ul>
          <li
            v-for="(item, index) in reportList"
            :key="index"
            @click="toReport(item)"
          >
            <p class="pes-year">{{ Strslice(item.semesterName) }}</p>
            <p class="pes-txt">
              {{
                item.semesterName +
                (item.testingLog.isEnd === "0" ? "初测试报告" : "末测试报告")
              }}
            </p>
          </li>
        </ul>
      </div>
      <div
        class="pes-content-null"
        v-else-if="$store.state.userInfo.teacherId && value === '3'"
      >
        <img src="../../../../assets/img/pes/Pic_Cs_Ks.png" alt="" />
        <p>请使用园长账号查看园所报告</p>
      </div>
      <div class="pes-content-null" v-else>
        <img src="../../../../assets/img/pes/Pic_Cs_Ks.png" alt="" />
        <p>暂无测试报告</p>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      isStu: true, // 选择学生
      isHide: true, // 判断其他
      options1: [
        {
          value: "1",
          label: "幼儿报告",
        },
        {
          value: "2",
          label: "班级报告",
        },
        {
          value: "3",
          label: "园所报告",
        },
      ],
      options2: [],
      options3: [],
      options4: [],
      value: "3",
      valueType: "", // 班级类型
      valueClass: "", // 选择班级
      valueStu: "", // 选择学生
      reportList: [], //报告测试

      isNum: "", // 用作判断显示报告
      cacheStuId: "", //当前学生id
      cacheStuName: "", //当前学生名称
      stuClass: "", //当前班级名称
      grade: "", //当前班级名称
      chengji: {},
    };
  },
  created() {
    this.bindPsType(3);
  },
  mounted() {
    this.findGradeType();
  },
  methods: {
    // 截图字符串
    Strslice(val) {
      return val.slice(0, 4);
    },
    // 获取班级
    findGradeType() {
      this.api.school.findGradeType().then((res) => {
        let list = res.data;
        this.options2 = list.map((item) => {
          console.log(item, "列表111111111111111111");
          return {
            value: item.dictValue,
            label:
              item.dictValue == 0
                ? "大班"
                : item.dictValue == 1
                ? "中班"
                : "小班",
          };
        });
      });
    },
    // 获取班级列表
    findByGradeType(id) {
      let data = {
        schoolId: this.$store.state.userInfo.schoolId,
        type: id,
      };
      this.api.pes.findByGradeType(data).then((res) => {
        console.log(res, "班级列表");
        let list = res.data;
        this.options3 = list.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
      });
    },
    // 获取班级学生
    findByGradeId(id) {
      let data = {
        gradeId: id,
      };
      this.api.pes.findByGradeId(data).then((res) => {
        console.log(res, "学生列表");
        let list = res.data;
        this.options4 = list.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
      });
    },
    // 获取学生报告
    findByStuId(id) {
      this.cacheStuId = id;
      console.log(this.options4, "this.options4");
      this.cacheStuName = this.options4.filter((item) => {
        return item.value === id;
      })[0].label;
      let data = {
        schoolId: this.$store.state.userInfo.schoolId,
        stuId: id,
      };
      this.api.pes.findByStuId(data).then((res) => {
        console.log(res, "报告出来吧");
        this.reportList = res.data;
      });
    },
    // 获取幼儿园报告
    findBySchoolId() {
      let data = {
        schoolId: this.$store.state.userInfo.schoolId,
      };
      this.api.pes.findBySchoolId(data).then((res) => {
        console.log(res, "园所报告出来吧");
        this.reportList = res.data;
      });
    },
    // 获取班级报告
    getGradeReportList(id) {
      let data = {
        schoolId: this.$store.state.userInfo.schoolId,
        gradeId: id,
      };
      this.api.pes.getGradeReportList(data).then((res) => {
        console.log(res, "班级报告出来吧");
        this.reportList = res.data;
      });
    },
    bindPsType(val) {
      console.log(val);
      this.isNum = val;
      if (val == 2) {
        this.reportList = [];
        this.isStu = false;
        this.isHide = true;
      } else if (val == 3) {
        this.isHide = false;
        this.isStu = false;
        this.findBySchoolId();
      } else if (val == 1) {
        this.reportList = [];
        this.isHide = true;
        this.isStu = true;
      }
      this.valueType = ""; // 班级类型
      this.valueClass = ""; // 选择班级
      this.valueStu = ""; // 选择学生
    },
    bindClass(val) {
      this.valueClass = ""; // 选择班级
      this.valueStu = ""; // 选择学生
      this.options3 = [];
      this.options4 = [];
      this.findByGradeType(val);
    },
    bindStu(val) {
      this.valueStu = ""; // 选择学生
      this.options4 = [];
      this.stuClass = this.options3.filter((item) => {
        return item.value == val;
      })[0].label;
      console.log(this.stuClass, "this.stuClass");
      if (this.isNum == 2) {
        this.getGradeReportList(val);
      } else {
        this.findByGradeId(val);
      }
    },
    bindBgao(val) {
      console.log(val);
      this.findByStuId(val);
    },
    toReport(item) {
      //跳转报告页面
      if (this.value === "3") {
        //园所报告
        this.toSchoolReport(item);
      } else if (this.value === "2") {
        this.toClassReport(item);
      } else {
        this.toStuReport(item);
      }
    },
    toStuReport(item) {
      //跳转学生报告
      let data = {
        stuId: this.cacheStuId,
      };
      this.api.pes.findReportAuthByStuId(data).then((res) => {
        if (res.flag == true) {
          this.$router.push({
            path: "/admin/testconter/stuReport",
            query: {
              class: this.stuClass,
              grade:
                this.valueType == 0
                  ? "大班"
                  : this.valueType == 1
                  ? "中班"
                  : "小班",
              stuReport:
                item.semesterName +
                (item.testingLog.isEnd === "0" ? "初测试报告" : "末测试报告"),
              isEnd: item.testingLog.isEnd,
              stuId: item.testingLog.stuId,
              semesterId: item.testingLog.semesterId,
              type: item.testingLog.type,
              stu: this.cacheStuName,
              gradeType: this.valueType,
            },
          });
        } else {
          this.$notice({
            message: res.message,
          }).isShow();
        }
      });
    },
    toClassReport(item) {
      //跳转班级报告
      let data = {
        gradeId: item.testingLog.gradeId,
        isEnd: item.testingLog.isEnd,
        semesterId: item.testingLog.semesterId,
      };
      this.api.pes.findReportByGradeId(data).then((res) => {
        this.chengji = JSON.stringify(res.data);
        if (res.flag == true) {
          this.$router.push({
            path: "/admin/testconter/classReport",
            query: {
              class: this.stuClass,
              grade:
                this.valueType == 0
                  ? "大班"
                  : this.valueType == 1
                  ? "中班"
                  : "小班",
              classReport:
                item.semesterName +
                (item.testingLog.isEnd === "0" ? "初测试报告" : "末测试报告"),
              isEnd: item.testingLog.isEnd,
              gradeId: item.testingLog.gradeId,
              semesterId: item.testingLog.semesterId,
              type: item.testingLog.type,
              // data: this.chengji,
            },
          });
        } else {
          this.$notice({
            message: res.message,
          }).isShow();
        }
      });
    },
    toSchoolReport(item) {
      //跳转园所报告
      let data = {
        semesterId: item.testingLog.semesterId,
        schoolId: item.testingLog.schoolId,
        isEnd: item.testingLog.isEnd,
      };
      this.api.pes.findSchoolTestPercent(data).then((res) => {
        this.loading = false;
        if (res.flag) {
          console.log("tiaozhuan");
          this.$router.push({
            path: "/admin/testconter/schoolReport",
            query: {
              schoolReport:
                item.semesterName +
                (item.testingLog.isEnd === "0" ? "初测试报告" : "末测试报告"),
              isEnd: item.testingLog.isEnd,
              semesterId: item.testingLog.semesterId,
              schoolId: item.testingLog.schoolId,
            },
          });
        } else {
          this.$notice({
            type: "success",
            message: res.message,
          }).isShow();
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.pes {
  width: 1434px;
  background: #fff;
  margin: 0 auto;
  padding: 40px;
}
.pes-title {
  font-size: 24px;
  font-family: Adobe Heiti Std;
  font-weight: bold;
  color: #333333;
  padding-bottom: 30px;
}
.pes-select {
  display: flex;
  .select-item {
    width: 130px;
    height: 38px;
    margin-right: 32px;
    /deep/ .el-input__inner {
      font-size: 18px;
    }
  }
}
.pes-content {
  background: #ffffff;
  box-shadow: 0px 2px 12px 0px rgba(189, 189, 189, 0.41);
  border-radius: 4px;
  margin-top: 20px;
  .pes-content-title {
    width: 100%;
    height: 42px;
    background: #ba3037;
    border-radius: 4px 4px 0 0;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    line-height: 42px;
  }
  .pes-content-null {
    text-align: center;
    padding: 67px 0;
    p {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #bbbbbb;
      margin-top: 19px;
    }
  }
  .pes-content-list {
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      padding: 20px;
      .active {
        background: url(../../../../assets/img/pes/Btn_Bg_PES_RP_Gray.png)
          no-repeat;
        background-size: 100% 100%;
      }
      li {
        width: 312px;
        height: 112px;
        background: url(../../../../assets/img/pes/Btn_Bg_PES_RP.png) no-repeat;
        background-size: 100% 100%;
        margin-right: 20px;
        cursor: pointer;
        .pes-year {
          font-size: 24px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #ffffff;
          margin: 28px 0 0 23px;
        }
        .pes-txt {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          margin: 10px 0 0 23px;
        }
      }
      li:hover {
        background: url(../../../../assets/img/pes/Btn_Bg_PES_RP_H.png)
          no-repeat;
        background-size: 100% 100%;
      }
      .active:hover {
        background: url(../../../../assets/img/pes/Btn_Bg_PES_RP_Gray_H.png)
          no-repeat;
        background-size: 100% 100%;
      }
    }
  }
}
</style>